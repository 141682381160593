import React, { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import List from '@material-ui/core/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNote';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import UpdateIcon from '@material-ui/icons/Update';
import PeopleIcon from '@material-ui/icons/People';
import ShareIcon from '@material-ui/icons/Share';
import { Typography, Grid, Button } from '@material-ui/core';
import CustomDialog from '../../components/CustomDialog';
import { Avatar, Divider, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import AddIcon from '@material-ui/icons/Add';
import questionIcon from '../../assets/svg/question.svg';
import rideIcon from '../../assets/svg/ride.svg';
import riderIcon from '../../assets/svg/rider.svg';
import passengerIcon from '../../assets/svg/passenger.svg';

import { useStyles } from './NavBarStyle';

import config from '../../config';
import routes from '../../config/routes';
import { CurrentUserDetails } from '../../core/context/user';
import defaultUser from '../../assets/images/default_user.png';
import dhukkaLogo from '../../assets/svg/dhukka_logo.svg';
import { removeToken } from '../../core/utils/token';
import { useRole } from '../../core/context/role';
import { useTitle } from '../../core/context/navbar';
import { useActiveTab } from '../../core/context/activeTab';
import CustomButton from '../Button';

const NavBar = () => {
  const classes = useStyles();
  const { currentUser, setCurrentUser } = useContext(
    CurrentUserDetails,
  );
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const {
    isServicePortalActive,
    isRidePortalActive,
    isTripPortalActive,
    setRoles,
    removeRoles,
  } = useRole();
  const { title } = useTitle();
  const { setValues } = useActiveTab();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeNav, setActiveNav] = useState<string>('');
  const [portalRole, setPortalRole] = useState<
    'service' | 'ride' | 'trip'
  >('ride');

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeToken({ name: config.tokenName });
    removeRoles();
    setCurrentUser({});
    history.go(0);
    history.push(routes.login.path);
  };

  const viewProfile = (e: any) => {
    e.stopPropagation();
    history.push(`/profile/${currentUser?.username}/`);
    setAnchorEl(null);
  };

  const isAdmin = currentUser?.groups?.group === config.roles.Admin;
  const isDispatcher =
    currentUser?.groups?.group === config.roles.Dispatcher;

  const onSwitchClick = (role: 'service' | 'ride' | 'trip') => {
    setRoles(role);
    setOpen(false);
    history.push(routes.landing.path);
    window.location.reload();
    handleClose();
  };

  const confirmModal = (
    <Grid
      container
      direction="column"
      className={classes.modalContainer}
    >
      <Grid container justify="center">
        <img src={questionIcon} alt="icon" />
      </Grid>
      <Typography variant="h5" color="textPrimary">
        Are You sure you want to switch your Role?{' '}
      </Typography>
      <Grid>
        <Button
          className={clsx(classes.btn, classes.btnSwitch)}
          onClick={() => onSwitchClick(portalRole)}
        >
          Switch Role
        </Button>
        <Button
          className={clsx(classes.btn, classes.btnCancel)}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  const handleSwitchPortal = (role: 'service' | 'ride' | 'trip') => {
    setOpen(true);
    setPortalRole(role);
  };

  return (
    <>
      <div
        className={
          isServicePortalActive ? classes.root : classes.rootMini
        }
        onClick={() => {
          setShowDropDown(false);
        }}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <div className={classes.navBar}>
            <div>
              <img
                src={dhukkaLogo}
                className={classes.dhukkaLogo}
                alt="Dhukka Logo"
              />
              <span className={classes.navTitle}>{title}</span>
            </div>

            {/* <Notifications /> */}
            {/* <div className={classes.navBorder} /> */}
            <div className={classes.navProfileHead}>
              {isDispatcher && isServicePortalActive && (
                <div className={classes.createTaskButton}>
                  <CustomButton
                    color="#ffffff"
                    background="#F45B23"
                    title="Create Task"
                    width="150px"
                    height="3rem"
                    onClick={() => history.push('/task/create/')}
                  />
                </div>
              )}
              <div className={classes.navProfile}>
                <Avatar
                  src={
                    currentUser?.profile_picture
                      ? currentUser?.profile_picture
                      : defaultUser
                  }
                  alt={`${currentUser?.display_name} Profile`}
                />{' '}
                &nbsp;
                <span className={classes.navProfileName}>
                  {currentUser?.first_name}
                </span>
                <span
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  className={classes.navExpandIcon}
                  onClick={handleClick}
                >
                  <ExpandMoreIcon />
                </span>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  classes={{ paper: classes.dropdownMenu }}
                >
                  {!isAdmin && (
                    <div>
                      {currentUser.groups && (
                        <div>
                          {currentUser?.groups?.is_ride_dispatcher &&
                            !isServicePortalActive && (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    handleSwitchPortal('service')
                                  }
                                >
                                  Switch to Service Dispatcher
                                </MenuItem>
                                <Divider />
                              </>
                            )}
                          {currentUser?.groups?.is_ride_dispatcher &&
                            !isRidePortalActive && (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    handleSwitchPortal('ride')
                                  }
                                >
                                  Switch to Ride Dispatcher
                                </MenuItem>
                                <Divider />
                              </>
                            )}
                          {currentUser?.groups?.is_ride_dispatcher &&
                            !isTripPortalActive && (
                              <>
                                <MenuItem
                                  onClick={() =>
                                    handleSwitchPortal('trip')
                                  }
                                >
                                  Switch to Trip Dispatcher
                                </MenuItem>
                                <Divider />
                              </>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                  <MenuItem onClick={viewProfile}>
                    My Profile
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </AppBar>
        <Drawer
          className={
            isServicePortalActive ? classes.navBar : classes.drawer
          }
          variant="permanent"
          classes={{
            paper: isServicePortalActive
              ? classes.drawerPaper
              : classes.miniNav,
          }}
        >
          <Toolbar />
          {isServicePortalActive ? (
            <div className={classes.sideNav}>
              <List>
                <ListItem
                  button
                  component={NavLink}
                  to={routes.landing.path}
                  activeClassName={classes.activeSideNav}
                  exact
                >
                  <DashboardIcon className={classes.sideNavIcons} />
                  <ListItemText primary="Dashboard" />
                </ListItem>
                {isAdmin && (
                  <ListItem
                    button
                    component={NavLink}
                    to={routes.notification.path}
                    activeClassName={classes.activeSideNav}
                    exact
                  >
                    <NotificationsActiveIcon
                      className={classes.sideNavIcons}
                    />
                    <ListItemText primary="Notifications" />
                  </ListItem>
                )}
                <ListItem
                  button
                  component={NavLink}
                  to={routes.serviceList.path}
                  activeClassName={classes.activeSideNav}
                  exact
                >
                  <SettingsIcon className={classes.sideNavIcons} />
                  <ListItemText primary="Services" />
                </ListItem>
              </List>

              <div className={classes.sideNavTitle}>BOOKINGS</div>
              <div className={classes.sideNavBorder} />
              <List>
                <ListItem
                  button
                  component={NavLink}
                  to={routes.bookingList.path}
                  activeClassName={classes.activeSideNav}
                  exact
                >
                  <EventNoteOutlinedIcon
                    className={classes.sideNavIcons}
                  />
                  <ListItemText primary="Bookings" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to={routes.taskList.path}
                  activeClassName={classes.activeSideNav}
                  exact
                >
                  <AssignmentTurnedInOutlinedIcon
                    className={classes.sideNavIcons}
                  />
                  <ListItemText primary="Tasks" />
                </ListItem>
              </List>

              {isAdmin && (
                <div>
                  <div className={classes.sideNavTitle}>
                    Applications
                  </div>
                  <div className={classes.sideNavBorder} />
                  <List>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.applicationList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <ListAltIcon className={classes.sideNavIcons} />
                      <ListItemText primary="Sahayogi Applications" />
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.riderApplicationList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <ListIcon className={classes.sideNavIcons} />
                      <ListItemText primary="Rider Applications" />
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.driverApplicationList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <DriveEtaIcon
                        className={classes.sideNavIcons}
                      />
                      <ListItemText primary="Driver Applications" />
                    </ListItem>
                  </List>
                </div>
              )}
              {isAdmin && (
                <div>
                  <div className={classes.sideNavTitle}>COMPANY</div>
                  <div className={classes.sideNavBorder} />
                  <List>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.companyList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <BusinessCenterIcon
                        className={classes.sideNavIcons}
                      />
                      <ListItemText primary="Company" />
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.companyAdminList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <PersonIcon className={classes.sideNavIcons} />
                      <ListItemText primary="Company Admin" />
                    </ListItem>
                  </List>
                </div>
              )}

              {/* Ride route start */}
              {!isDispatcher && (
                <>
                  <div className={classes.sideNavTitle}>RIDE</div>
                  <div className={classes.sideNavBorder} />
                  <List>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.riderList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <DirectionsBikeIcon
                        className={classes.sideNavIcons}
                      />
                      <ListItemText primary="Riders" />
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.rideHistory.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <UpdateIcon className={classes.sideNavIcons} />
                      <ListItemText primary="Ride History" />
                    </ListItem>
                  </List>
                </>
              )}
              {/* Ride route end */}

              {/* Trip route start */}
              {!isDispatcher && (
                <>
                  <div className={classes.sideNavTitle}>TRIP</div>
                  <div className={classes.sideNavBorder} />
                  <List>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.driverList.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <DriveEtaIcon
                        className={classes.sideNavIcons}
                      />
                      <ListItemText primary="Drivers" />
                    </ListItem>
                    <ListItem
                      button
                      component={NavLink}
                      to={routes.tripHistory.path}
                      activeClassName={classes.activeSideNav}
                      exact
                    >
                      <UpdateIcon className={classes.sideNavIcons} />
                      <ListItemText primary="Trip History" />
                    </ListItem>
                  </List>
                </>
              )}
              {/* Ride route end */}

              {/* User route start */}
              <div className={classes.sideNavTitle}>USERS</div>
              <div className={classes.sideNavBorder} />
              <List>
                {!isDispatcher && (
                  <ListItem
                    button
                    component={NavLink}
                    to={routes.users.path}
                    activeClassName={classes.activeSideNav}
                    exact
                  >
                    <PeopleIcon className={classes.sideNavIcons} />
                    <ListItemText primary="Users" />
                  </ListItem>
                )}
                <ListItem
                  button
                  component={NavLink}
                  to={routes.sahayogiList.path}
                  activeClassName={classes.activeSideNav}
                  exact
                >
                  <PersonIcon className={classes.sideNavIcons} />
                  <ListItemText primary="Sahayogi" />
                </ListItem>
                {!isDispatcher && (
                  <ListItem
                    button
                    component={NavLink}
                    to={routes.dispatchers.path}
                    activeClassName={classes.activeSideNav}
                    exact
                  >
                    <ShareIcon className={classes.sideNavIcons} />
                    <ListItemText primary="Dispatcher" />
                  </ListItem>
                )}
              </List>
              {/* User route end */}
            </div>
          ) : isRidePortalActive ? (
            <div className={classes.miniNav}>
              <List className={classes.list}>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.landing.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <DashboardIcon />
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.ride.path}
                  activeClassName={
                    activeNav === 'ride' ? classes.activeSideNav : ''
                  }
                  className={classes.listItem}
                  onClick={() => {
                    setValues('ride');
                    setActiveNav('ride');
                  }}
                >
                  <img src={rideIcon} alt="ride icon" />
                  <Typography>Rides</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.rideHistory.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <UpdateIcon />
                  <Typography>Ride History</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  onClick={() => {
                    setValues('riders');
                    setActiveNav('riders');
                  }}
                  to={routes.ride.path}
                  exact
                  activeClassName={
                    activeNav === 'riders'
                      ? classes.activeSideNav
                      : ''
                  }
                  className={classes.listItem}
                >
                  <img src={riderIcon} alt="rider icon" />
                  <Typography>Riders</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.passenger.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <img src={passengerIcon} alt="passenger icon" />
                  <ListItemText primary="Passenger" />
                </ListItem>
                <div
                  className={clsx(
                    classes.listItem,
                    classes.addIconContainer,
                  )}
                >
                  <div className={classes.addIcon}>
                    <AddIcon
                      className={classes.svg}
                      onClick={() => {
                        setValues('create-ride');
                        history.push(routes.ride.path);
                      }}
                    />
                  </div>
                </div>
              </List>
            </div>
          ) : (
            <div className={classes.miniNav}>
              <List className={classes.list}>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.landing.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <DashboardIcon />
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.ride.path}
                  activeClassName={
                    activeNav === 'trips' ? classes.activeSideNav : ''
                  }
                  className={classes.listItem}
                  onClick={() => {
                    setValues('trips');
                    setActiveNav('trips');
                  }}
                >
                  <DriveEtaIcon />
                  <Typography>Trips</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.tripHistory.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <UpdateIcon />
                  <Typography>Trip History</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  onClick={() => {
                    setValues('drivers');
                    setActiveNav('drivers');
                  }}
                  to={routes.ride.path}
                  exact
                  activeClassName={
                    activeNav === 'drivers'
                      ? classes.activeSideNav
                      : ''
                  }
                  className={classes.listItem}
                >
                  <PersonIcon />
                  <Typography>Drivers</Typography>
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  exact
                  to={routes.passenger.path}
                  activeClassName={classes.activeSideNav}
                  className={classes.listItem}
                >
                  <img src={passengerIcon} alt="passenger icon" />
                  <ListItemText primary="Passenger" />
                </ListItem>
                <div
                  className={clsx(
                    classes.listItem,
                    classes.addIconContainer,
                  )}
                >
                  <div className={classes.addIcon}>
                    <AddIcon
                      className={classes.svg}
                      onClick={() => {
                        setValues('create-ride');
                        history.push(routes.ride.path);
                      }}
                    />
                  </div>
                </div>
              </List>
            </div>
          )}

          {showDropDown && (
            <div className={classes.dropdownMenu}>
              <div
                className={classes.dropdownMenuItem}
                onClick={() => setOpen(true)}
              >
                Switch to Ride Dispatcher
              </div>
              <hr />
              <div
                className={classes.dropdownMenuItem}
                onClick={viewProfile}
              >
                View Profile
              </div>
              <hr />
              <div
                onClick={handleLogout}
                className={classes.dropdownMenuItem}
              >
                Logout
              </div>
            </div>
          )}
        </Drawer>
      </div>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        children={confirmModal}
      />
    </>
  );
};

export default NavBar;
