import { createStyles, makeStyles, Theme } from '@material-ui/core';

const mainStyle = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '42px',
    },
    subTitle: {
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '25px',
    },
    submitButton: {
      width: '220px',
      height: '43px',
      color: '#FFFFFF',
      backgroundColor: '#F15A25',
      border: 'none',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#F15A25',
      },

      fontWeight: 400,
      fontSize: '1.02rem',
      textTransform: 'capitalize',
    },
    cancelButton: {
      marginLeft: '5%',
      width: '220px',
      height: '43px',
      color: '#000000',
      backgroundColor: '#FFFFFF',
      border: 'none',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      fontWeight: 400,
      fontSize: '1.02rem',
      textTransform: 'capitalize',
    },
  }),
);

export const toastStyle = {
  success: {
    style: {
      background: 'green',
      padding: '10px 20px',
      color: 'white',
    },
  },
  error: {
    style: {
      background: 'red',
      padding: '10px 20px',
      color: 'white',
    },
  },
}

export { mainStyle };
