export const formatDate = (date: string) => {
  const newDate = new Date(date);
  return newDate.toDateString();
};

export const getNavigationName = () => {
  const paths = window.location.pathname;
  if (paths.includes('/services/add')) return 'Add Services';
  if (paths.includes('/bookings/')) return 'Booking List';
  if (paths.indexOf('/services/') === 0) return 'Services';
  if (paths.indexOf('/service/') === 0) return 'Service';
  if (paths.indexOf('/companies/') === 0) return 'Companies';
  if (paths.indexOf('/company-add/') === 0) return 'Add Company';
  if (paths.indexOf('/company') === 0) return 'Company';
  if (paths.indexOf('/booking/') === 0) return 'Booking';
  if (paths.indexOf('/tasks/') === 0) return 'Tasks';
  if (paths.indexOf('/task/') === 0) return 'Task';
  if (paths.indexOf('/applications/') === 0) return 'Applications';
  if (paths.indexOf('/application/') === 0) return 'Application';
  if (paths.indexOf('/sahayogi/') === 0) return 'Sahayogi';
  if (paths.indexOf('/sahayogi-add/') === 0) return 'Add Sahayogi';
  if (paths.indexOf('/rider-add/') === 0) return 'Add Riders';
  if (paths.indexOf('/users') === 0) return "Users' List";
  if (paths.indexOf('/profile') === 0) return 'Profile';
  if (paths.indexOf('/rider') === 0) return 'Rider';
  if (paths.indexOf('/driver') === 0) return 'Driver';
  if (paths.indexOf('/trip-history') === 0) return 'Trip History';
  if (paths.indexOf('/ride-history') === 0) return 'Ride History';
  if (paths.indexOf('/dispatcher') === 0) return 'Dispatcher';
  if (paths.indexOf('/notification') === 0) return 'Notification';
  if (paths.includes('/')) return 'Dashboard';
  return '';
};

export const getStatusColor = (status: string) => {
  if (status === 'APPROVED') {
    return {
      color: '#299E00',
      backgroundColor: '#5AEB02',
    };
  }
  if (status === 'PENDING') {
    return {
      color: '#972C04',
      backgroundColor: '#F88257',
    };
  }
  if (status === 'REJECTED') {
    return {
      color: '#750707',
      backgroundColor: '#FF1F00',
    };
  }
  if (status === 'COMPLETED') {
    return {
      color: '#C0C0C0',
      backgroundColor: '#615E5E',
    };
  }
  if (status === 'ONGOING') {
    return {
      color: '#0063AA',
      backgroundColor: '#58B1F1',
    };
  }
};
