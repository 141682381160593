import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles} from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        loadingPosition: {
            margin: '20% 45%',
        },
    })
)
const ScreenLoader = () => {
    const classes = useStyles();
    return(
        <div className={classes.loadingPosition}>
            <CircularProgress />
            <h1>Loading...</h1>
        </div>
        )
}

export default ScreenLoader;