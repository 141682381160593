import { useEffect, Suspense } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";

import ScreenLoader from "../ScreenLoader";
import {getToken} from "../../core/utils/token";

import config from '../../config'
import routes from "../../config/routes";

interface IProps extends RouteProps{}


const UnAuthenticatedRoute = (props: IProps) => {
    const history = useHistory();
    const token = getToken({name: config.tokenName});
    useEffect(() =>{
        if(token){
            history.push(routes.landing.path)
        }
    },[history, token])

    if(token){
        return null;
    }

    return (
        <Suspense fallback={(<ScreenLoader/>)}>
            <Route {...props}/>
        </Suspense>
    )
}

export default UnAuthenticatedRoute
