// import axios from "../utils/axios";
import Http from './Http';
import {
  IOtpVerification,
  IPasswordChange,
  IPasswordReset,
} from '../interfaces/user';
const http = new Http();

class UserService {
  static getUsers(filter: any) {
    return http.get<any>({
      endpoint: '/users/',
      payload: { params: filter },
    });
  }

  static registerUser(userData: FormData) {
    return http.post<any>({
      endpoint: '/users/register/',
      payload: userData,
    });
  }

  static getCurrentUser() {
    return http.get<any>({
      endpoint: '/users/current-user/',
    });
  }

  static getProfile(username: string) {
    return http.get({
      endpoint: `/users/${username}/`,
    });
  }

  static updateProfile(username: string, userData: FormData) {
    return http.patch({
      endpoint: `/users/${username}/`,
      payload: userData,
    });
  }

  static verifyOtp(otpData: IOtpVerification) {
    return http.post({
      endpoint: '/users/verify-phonenumber/',
      payload: otpData,
    });
  }

  static resetPassword(userData: IPasswordReset) {
    return http.post({
      endpoint: '/users/password-reset/',
      payload: userData,
    });
  }

  static changePassword(passwordData: IPasswordChange) {
    return http.post({
      endpoint: '/users/change-password/',
      payload: passwordData,
    });
  }

  static otpResend(userData: { phone_number: string }) {
    return http.post({
      endpoint: '/users/otp-request/',
      payload: userData,
    });
  }

  static deleteUser(username: string) {
    return http.delete({
      endpoint: `/users/${username}/`,
    });
  }

  static onboardUser(userData: FormData) {
    return http.post<any>({
      endpoint: '/users/onboard/',
      payload: userData,
    });
  }

  static getAutomobileUsers(data: { customer_username: string }) {
    return http.get<any>({
      endpoint: `/users/sahayogi-automobile/`,
      payload: { params: data },
    });
  }
}
export default UserService;
