import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

const drawerWidth = '300px';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '9%',
      width: '300px',
    },
    rootMini: {
      width: '8.2rem',
    },
    navBar: {
      position: 'fixed',
      minWidth: '100%',
      height: '82px',
      left: '0px',
      top: '0px',
      backgroundColor: '#FFFFFF',
      zIndex: 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    dhukkaLogo: {
      position: 'absolute',
      height: '95px',
      width: '134px',
      left: '2%',
      top: '6px',
    },
    navTitle: {
      position: 'absolute',
      width: '12%',
      height: '33px',
      left: '16%',
      top: '24px',
      color: '#000000',
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '33px',
      fontStyle: 'normal',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    navBorder: {
      position: 'absolute',
      width: '0px',
      height: '82px',
      left: '86%',
      top: '0px',
      // border: '1px solid #d3d3d3',
      // borderLeft: '2px solid #d3d3d3',
    },
    navProfileHead: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    createTaskButton: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
    },
    navProfile: {
      padding: '10px',
      height: '82px',
      borderLeft: '2px solid #d3d3d3',
      display: 'flex',
      alignItems: 'center',
      width: '200px',
    },
    navProfileImage: {
      height: '50px',
      width: '50px',
      borderRadius: '50%',
      marginRight: '10%',
    },
    navProfileName: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#000000',
      margin: '5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      [theme.breakpoints.down('md')]: {
        left: '45%',
      },
    },
    navExpandIcon: {
      color: '#d3d3d3',
      transition: '1s',
      '&:hover': {
        transform: 'scale(1.1)',
        color: 'blue',
      },
      [theme.breakpoints.down('md')]: {
        left: '95%',
      },
      [theme.breakpoints.down('sm')]: {
        left: '75%',
      },
    },
    sideNav: {
      position: 'fixed',
      backgroundColor: '#FFFFFF',
      height: '91vh',
      width: '300px',
      top: '82px',
      paddingTop: '1rem',
      zIndex: 4,
      overflowY: 'auto',
    },
    sideNavBorder: {
      position: 'absolute',
      width: '288px',
      height: '0px',
      left: '0',
      border: '1px solid #d3d3d3',
    },
    sideNavTitle: {
      margin: '10px 0 5px 30px',
      fontFamily: 'Poppins, sans-serif',
      fontStyle: 'normal',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '23px',
    },
    sideNavIcons: {
      marginLeft: '10px',
      marginRight: '18px',
    },
    activeSideNav: {
      color: '#F45B23 !important',
      backgroundColor: '#FCFCFC',
      borderRight: '4px solid #F45B23',
      '& img': {
        filter:
          'invert(54%) sepia(50%) saturate(6591%) hue-rotate(349deg) brightness(100%) contrast(92%)',
      },
    },
    dropdownMenu: {
      top: '10px',
      borderRadius: '15px',
      padding: '1px 10px',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    miniNav: {
      marginTop: '2rem',
      width: '8.2rem',
      background: '#ffffff',
      display: 'flex',
    },
    list: {
      height: '100%',
    },
    listItem: {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'column',
      color: '#000000',
      '&:hover': {
        background: '#',
      },
    },
    addIconContainer: {
      height: '40vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {},
    addIcon: {
      background: '#F45B23',
      height: '5rem',
      width: '5rem',
      borderRadius: '50%',
      color: '#ffffff',
    },
    dropdownMenuItem: {
      cursor: 'pointer',
    },
    svg: {
      color: '#ffffff',
      fontSize: '3rem',
      marginTop: '1rem',
      marginLeft: '1rem',
      cursor: 'pointer',
    },
    modalContainer: {
      height: '25rem',
      textAlign: 'center',
      '& img': {
        marginTop: '3rem',
        marginBottom: '2rem',
      },
    },
    btn: {
      height: '3rem',
      width: '10rem',
      marginTop: '3rem',
      marginRight: '0.5rem',
    },
    btnSwitch: {
      background: '#F35B23',
      color: '#ffffff',
      '&:hover': {
        background: '#F35B23',
      },
    },
    btnCancel: {
      background: '#F5F5F5',
    },
  }),
);

export { useStyles };
