import { Button } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  title?: string;
  onClick?: any;
  color: string;
  background: string;
  width?: string;
  height?: string;
  disabled?: boolean;
}

const CustomButton = (props: Props) => {
  const {
    title,
    onClick,
    color,
    background,
    width,
    height,
    disabled = false,
  } = props;
  const classes = useStyles({ color, background, height, width });
  return (
    <Button
      disabled={disabled}
      className={classes.addBtn}
      onClick={() => onClick()}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
