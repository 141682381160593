import { lazy } from 'react';

import * as paths from '../core/constants/routes';

const routes = {
  landing: {
    path: paths.LANDING,
    component: lazy(() => import('../pages/landing/LandingPage')),
  },
  login: {
    path: paths.loginForm,
    component: lazy(() => import('../pages/user/LoginForm')),
  },
  signUp: {
    path: paths.signUpForm,
    component: lazy(() => import('../pages/user/SignUpForm')),
  },
  notification: {
    path: paths.notification,
    component: lazy(
      () => import('../pages/notification/NotificationList'),
    ),
  },
  notificationForm: {
    path: paths.notificationForm,
    component: lazy(
      () => import('../pages/notification/NotificationCreate'),
    ),
  },
  notificationEdit: {
    path: paths.notificationEdit,
    component: lazy(
      () => import('../pages/notification/NotificationEdit'),
    ),
  },
  serviceForm: {
    path: paths.serviceForm,
    component: lazy(() => import('../pages/service/ServiceForm')),
  },
  serviceEdit: {
    path: paths.serviceEdit,
    component: lazy(() => import('../pages/service/ServiceEdit')),
  },
  serviceList: {
    path: paths.serviceList,
    component: lazy(() => import('../pages/service/ServiceList')),
  },
  serviceDetails: {
    path: paths.serviceDetail,
    component: lazy(() => import('../pages/service/ServiceDetail')),
  },
  subServiceDetails: {
    path: paths.subServiceDetail,
    component: lazy(
      () => import('../pages/subService/SubServiceDetail'),
    ),
  },
  subServiceForm: {
    path: paths.subServiceForm,
    component: lazy(
      () => import('../pages/subService/SubServiceForm'),
    ),
  },
  subServiceEdit: {
    path: paths.subServiceEdit,
    component: lazy(
      () => import('../pages/subService/SubServiceEdit'),
    ),
  },
  questionForm: {
    path: paths.questionAdd,
    component: lazy(() => import('../pages/subService/QuestionForm')),
  },
  questionEdit: {
    path: paths.questionEdit,
    component: lazy(() => import('../pages/subService/QuestionEdit')),
  },
  applicationList: {
    path: paths.applicationList,
    component: lazy(
      () => import('../pages/application/ApplicationList'),
    ),
  },
  riderApplicationList: {
    path: paths.riderApplicationList,
    component: lazy(
      () => import('../pages/application/ApplicationList'),
    ),
  },
  driverApplicationList: {
    path: paths.driverApplicationList,
    component: lazy(
      () => import('../pages/application/DriverApplicationList'),
    ),
  },
  applicationDetails: {
    path: paths.applicationDetail,
    component: lazy(
      () => import('../pages/application/ApplicationDetail'),
    ),
  },
  riderApplicationDetails: {
    path: paths.riderApplicationDetail,
    component: lazy(
      () => import('../pages/application/RiderApplicationDetail'),
    ),
  },
  driverApplicationDetails: {
    path: paths.driverApplicationDetail,
    component: lazy(
      () => import('../pages/application/DriverApplicationDetail'),
    ),
  },
  userProfile: {
    path: paths.profile,
    component: lazy(() => import('../pages/user/UserProfile')),
  },
  userProfileEdit: {
    path: paths.profileEdit,
    component: lazy(() => import('../pages/user/EditUserForm')),
  },
  bookingList: {
    path: paths.bookingList,
    component: lazy(() => import('../pages/booking/BookingList')),
  },
  taskCreate: {
    path: paths.taskCreate,
    component: lazy(() => import('../pages/booking/BookingCreate')),
  },
  bookingDetail: {
    path: paths.bookingDetail,
    component: lazy(() => import('../pages/booking/BookingDetail')),
  },
  taskList: {
    path: paths.tasksList,
    component: lazy(() => import('../pages/booking/TaskList')),
  },
  taskDetail: {
    path: paths.taskDetail,
    component: lazy(() => import('../pages/booking/TaskDetail')),
  },
  sahayogiList: {
    path: paths.sahayogiList,
    component: lazy(() => import('../pages/sahayogi/SahayogiList')),
  },
  sahayogiForm: {
    path: paths.sahayogiForm,
    component: lazy(() => import('../pages/sahayogi/SahayogiAdd')),
  },
  sahayogiDetail: {
    path: paths.sahayogiDetail,
    component: lazy(() => import('../pages/sahayogi/SahayogiDetail')),
  },

  notFoundPage: {
    path: paths.notFound,
    component: lazy(() => import('../pages/landing/NotFoundPage')),
  },
  phoneVerification: {
    path: paths.phoneVerification,
    component: lazy(() => import('../pages/user/PhoneVerification')),
  },
  resetPassword: {
    path: paths.resetPassword,
    component: lazy(() => import('../pages/user/ResetPasswordPage')),
  },
  verifyOtp: {
    path: paths.verifyOtp,
    component: lazy(() => import('../pages/user/OtpRequestVerify')),
  },
  resetSuccessPage: {
    path: paths.successResetPage,
    component: lazy(() => import('../pages/user/ResetSuccessPage')),
  },
  riderList: {
    path: paths.riderList,
    component: lazy(() => import('../pages/rider/RiderList')),
  },
  riderDetail: {
    path: paths.riderDetail,
    component: lazy(() => import('../pages/rider/RiderDetail')),
  },
  riderAdd: {
    path: paths.riderAdd,
    component: lazy(() => import('../pages/rider/RiderAdd')),
  },
  rideHistory: {
    path: paths.rideHistory,
    component: lazy(() => import('../pages/ride/RideHistory')),
  },
  driverList: {
    path: paths.driverList,
    component: lazy(() => import('../pages/driver/DriverList')),
  },
  driverDetail: {
    path: paths.driverDetail,
    component: lazy(() => import('../pages/driver/DriverDetail')),
  },
  driverAdd: {
    path: paths.driverAdd,
    component: lazy(() => import('../pages/driver/DriverAdd')),
  },
  driverEdit: {
    path: paths.driverEdit,
    component: lazy(() => import('../pages/driver/DriverEdit')),
  },
  tripHistory: {
    path: paths.tripHistory,
    component: lazy(() => import('../pages/trip/TripHistory')),
  },
  tripList: {
    path: paths.tripList,
    component: lazy(() => import('../pages/rideDispatcher/Ride')),
  },
  users: {
    path: paths.users,
    component: lazy(() => import('../pages/user/UserList')),
  },
  dispatcherForm: {
    path: paths.dispatcherForm,
    component: lazy(
      () => import('../pages/dispatcher/AddDispatcher'),
    ),
  },
  dispatcherEdit: {
    path: paths.dispatcherEdit,
    component: lazy(
      () => import('../pages/dispatcher/DispatcherEdit'),
    ),
  },
  dispatchers: {
    path: paths.dispatchers,
    component: lazy(
      () => import('../pages/dispatcher/DispatcherList'),
    ),
  },
  editSahayogi: {
    path: paths.sahayogiEdit,
    component: lazy(() => import('../pages/sahayogi/SahayogiEdit')),
  },
  ride: {
    path: paths.ride,
    component: lazy(() => import('../pages/rideDispatcher/Ride')),
  },
  editRider: {
    path: paths.riderEdit,
    component: lazy(() => import('../pages/rider/RiderEdit')),
  },
  passenger: {
    path: paths.passenger,
    component: lazy(
      () => import('../pages/rideDispatcher/Passenger'),
    ),
  },
  passengerHistory: {
    path: paths.passengerHistory,
    component: lazy(
      () => import('../pages/rideDispatcher/PassengerRideHistory'),
    ),
  },
  verifyPhone: {
    path: paths.verify,
    component: lazy(() => import('../pages/user/PhoneVerification')),
  },
  companyList: {
    path: paths.companyList,
    component: lazy(() => import('../pages/company/CompanyList')),
  },
  companyForm: {
    path: paths.companyForm,
    component: lazy(() => import('../pages/company/CompanyForm')),
  },
  companyEdit: {
    path: paths.companyEdit,
    component: lazy(() => import('../pages/company/CompanyEdit')),
  },
  companyAdminList: {
    path: paths.companyAdminList,
    component: lazy(
      () => import('../pages/companyAdmin/CompanyAdminList'),
    ),
  },
  companyAdminForm: {
    path: paths.companyAdminForm,
    component: lazy(
      () => import('../pages/companyAdmin/CompanyAdminForm'),
    ),
  },
  companyAdminEdit: {
    path: paths.companyAdminEdit,
    component: lazy(
      () => import('../pages/companyAdmin/CompanyAdminEdit'),
    ),
  },
};

export default routes;
