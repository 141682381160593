import { useEffect, Suspense, useContext } from 'react';
import {
  Route,
  RouteProps,
  Redirect,
  useHistory,
} from 'react-router-dom';
import _ from 'lodash';

import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';

import routes from '../../config/routes';
import NavBar from '../NavBar';
import { CurrentUserDetails } from '../../core/context/user';
import UserService from '../../core/services/User';
import ScreenLoader from '../ScreenLoader';
import auth from '../../config/auth';
import { IRouteProps } from '../../core/interfaces/IAuth';
import { useTitle } from '../../core/context/navbar';
import { getNavigationName } from '../../core/constants/functions';
import { useRole } from '../../core/context/role';
import { useStyles } from './style';

const AuthenticatedRoute = ({
  Component,
  path,
  exact = false,
  roles,
}: IRouteProps) => {
  const isAuthed = auth.isAuthenticated();
  const user = useContext(CurrentUserDetails);
  const classes = useStyles();
  const history = useHistory();
  const { setValues } = useTitle();
  const {
    isServicePortalActive,
    isRidePortalActive,
    isTripPortalActive,
    setRoles,
    removeRoles,
  } = useRole();

  let group: string = user?.currentUser?.groups?.group;

  useEffect(() => {
    if (isAuthed && _.isEmpty(user.currentUser)) {
      UserService.getCurrentUser()
        .then((response: any) => {
          if (response.status === 200) {
            user.setCurrentUser(response.data);
          }
        })
        .catch((err) => {
          history.push(routes.login.path);
        });
    }
  }, [user, isAuthed, history]);

  useEffect(() => {
    setValues(getNavigationName());
  });

  if (isAuthed && _.isEmpty(user.currentUser)) {
    return null;
  }

  if (
    !isServicePortalActive &&
    !isRidePortalActive &&
    !isTripPortalActive
  ) {
    setRoles('service');
  }

  if (user?.currentUser?.groups?.group === 'ADMIN') {
    removeRoles();
  }

  const userHasRequiredRole =
    isAuthed && roles && roles.includes(group);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <div
        className={
          isServicePortalActive ? classes.sideDrawer : classes.miniNav
        }
      >
        <NavBar />
      </div>
      <main
        className={
          !isServicePortalActive
            ? classes.miniContent
            : classes.content
        }
      >
        <Toolbar className={classes.mainToolBar} />
        <Suspense fallback={<ScreenLoader />}>
          <Route
            exact={exact}
            path={path}
            render={(props: RouteProps) =>
              userHasRequiredRole ? (
                <Component {...props} />
              ) : (
                <Redirect
                  to={{
                    pathname: isAuthed
                      ? routes.landing.path
                      : routes.login.path,
                    state: {
                      requestedPath: path,
                    },
                  }}
                />
              )
            }
          />
        </Suspense>
      </main>
    </div>
  );
};

export default AuthenticatedRoute;
