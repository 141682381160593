export const LANDING = '/';
export const notFound = '/not-found';
export const signUpForm = '/register';
export const notification = '/notification';
export const notificationForm = '/notification/create';
export const notificationEdit = '/notification/:uuid/edit/';
export const loginForm = '/login';
export const profile = '/profile/:username/';
export const profileEdit = '/profile/:username/edit';
export const applicationList = '/applications/';
export const riderApplicationList = '/rider-applications/';
export const driverApplicationList = '/driver-applications/';
export const applicationDetail = '/application/:uuid/';
export const riderApplicationDetail = '/rider-application/:uuid/';
export const driverApplicationDetail = '/driver-application/:uuid/';
export const serviceList = '/services/';
export const serviceForm = '/services/add/';
export const serviceDetail = '/service/:slug/:uuid/';
export const serviceEdit = '/service/:slug/:uuid/edit/';
export const subServiceDetail = '/service/:slug/sub-service/:uuid';
export const subServiceForm =
  '/service/:slug/:serviceUuid/sub-service/add';
export const subServiceEdit = '/service/:slug/sub-service/:uuid/edit';
export const questionAdd =
  '/service/:slug/sub-service/:subServiceUUID/question/add/';
export const questionEdit = '/service/:slug/question/:uuid/edit/';
export const bookingList = '/bookings/';
export const taskCreate = '/task/create/';
export const tasksList = '/tasks/';
export const bookingDetail = '/:serviceCode/booking/:uuid';
export const taskDetail = '/:serviceCode/task/:uuid';
export const sahayogiList = '/sahayogi/';
export const sahayogiForm = '/sahayogi-add/';
export const sahayogiEdit = '/sahayogi-add/:uuid/edit/';
export const sahayogiDetail = '/sahayogi/:uuid/';
export const phoneVerification = '/phoneVerification';
export const resetPassword = '/resetPassword';
export const verifyOtp = '/verifyOtp';
export const successResetPage = '/success';
export const rideHistory = '/ride-history';
export const riderList = '/riders/';
export const riderAdd = '/rider-add/';
export const tripHistory = '/trip-history';
export const tripList = '/trips/';
export const driverList = '/drivers/';
export const driverAdd = '/driver-add/';
export const driverDetail = '/driver/:uuid/';
export const driverEdit = '/driver/:uuid/edit';
export const users = '/users';
export const dispatcherForm = '/dispatcher-add';
export const dispatcherEdit = '/dispatcher/:uuid/edit';
export const dispatchers = '/dispatchers';
export const riderDetail = '/rider/:uuid/';
export const ride = '/ride/';
export const riderEdit = '/rider/:uuid/edit';
export const passenger = '/passengers';
export const passengerHistory = '/passenger/:uuid';
export const verify = '/verify';
export const companyList = '/companies/';
export const companyForm = '/company-add/';
export const companyEdit = '/company/:uuid/edit/';
export const companyAdminList = '/company-admin/';
export const companyAdminForm = '/company-admin/add/';
export const companyAdminEdit = '/company-admin/:uuid/edit/';
