import { createContext, useContext } from 'react';
const RoleContext = createContext({});

const dispatcherRideActive = 'dispatcher-ride-active';
const dispatcherTripActive = 'dispatcher-trip-active';
const dispatcherServiceActive = 'dispatcher-service-active';
const activePortalNames = [
  { name: 'service', value: dispatcherServiceActive },
  { name: 'ride', value: dispatcherRideActive },
  { name: 'trip', value: dispatcherTripActive },
];
const getItem = (name: string): string | null => {
  return localStorage.getItem(name);
};

const setItem = (name: string, value: string) => {
  localStorage.setItem(name, value);
};

const removeItem = (name: string) => {
  localStorage.removeItem(name);
};

export const RoleProvider = ({ children }: any) => {
  const isServicePortalActive = getItem(
    dispatcherServiceActive,
  )?.toString();
  const isRidePortalActive = getItem(
    dispatcherRideActive,
  )?.toString();
  const isTripPortalActive = getItem(
    dispatcherTripActive,
  )?.toString();

  const setRoles = (name: string) => {
    if (isTripPortalActive) {
      removeItem(dispatcherTripActive);
    }
    if (isRidePortalActive) {
      removeItem(dispatcherRideActive);
    }
    if (isServicePortalActive) {
      removeItem(dispatcherServiceActive);
    }
    const active = activePortalNames.find(
      (portalName) => portalName.name === name,
    );
    if (active) {
      setItem(active.value, active.value);
    }
  };
  const removeRoles = (name?: string) => {
    if (name) {
      removeItem(name);
    }
    if (isTripPortalActive) {
      removeItem(dispatcherTripActive);
    }
    if (isRidePortalActive) {
      removeItem(dispatcherRideActive);
    }
  };

  return (
    <RoleContext.Provider
      value={{
        isServicePortalActive,
        isRidePortalActive,
        isTripPortalActive,
        setRoles,
        removeRoles,
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

export const useRole: any = () => useContext(RoleContext);
