import {
  createStyles,
  Theme,
  makeStyles,
} from '@material-ui/core/styles';

const drawerWidth = '300px';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      overflow: 'hidden',
      backgroundColor: '#F5F5F5',
    },
    miniContent: {
      flexGrow: 1,
      height: '100vh',
    },
    mainToolBar: {
      height: '5rem',
    },
    sideDrawer: {
      width: '300px',
    },
    miniNav: {
      width: '8.2rem',
    },
  }),
);
