import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
interface IProps {
  open: boolean;
  setOpen: Function;
  children: React.ReactChild;
  maxWidth?: 'sm' | 'md' | 'lg';
}

const CustomDialog = (props: IProps) => {
  const { open, setOpen, children, maxWidth = 'sm' } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={maxWidth}
      >
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
};
export default CustomDialog;
