import {
  createContext,
  useState,
  useContext,
  ReactChild,
} from 'react';

const ServiceAvailability = createContext({});

interface IProps {
  children: ReactChild;
}

export const ServiceAvailabilityProvider = ({
  children,
}: IProps) => {
  const [serviceAvailabilityId, setServiceAvailabilityId] = useState(
    '',
  );

  const setValues = (value: string) => {
    setServiceAvailabilityId(value);
  };

  return (
    <ServiceAvailability.Provider
      value={{
        serviceAvailabilityId,
        setValues,
      }}
    >
      {children}
    </ServiceAvailability.Provider>
  );
};

export const useServiceAvailability: any = () =>
  useContext(ServiceAvailability);
