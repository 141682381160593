import { getToken } from './../core/utils/token';
import config from './index';
class Auth {
  authenticated: boolean;
  constructor() {
    this.authenticated = false;
  }
  isAuthenticated() {
    this.authenticated = !!getToken({name:config.tokenName})
    return this.authenticated;
  }
}

export default new Auth();
