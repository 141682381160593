import { BrowserRouter as Router, Switch } from 'react-router-dom';

import routes from '../config/routes';
import config from '../config';

import AuthenticatedRoute from '../components/AuthenticatedRoute';
import UnauthenticatedRoute from '../components/UnAuthenticatedRoute';
import BasicRoute from '../components/Route';

const common = [
  config.roles.Admin,
  config.roles.Dispatcher,
  config.roles.CompanyAdmin,
];
const admin = [config.roles.Admin];
const dispatcher = [config.roles.Dispatcher];
// const adminDispatcher = [config.roles.Admin, config.roles.Dispatcher];
const adminCompanyAdmin = [
  config.roles.Admin,
  config.roles.Dispatcher,
  config.roles.CompanyAdmin,
];
// const dispatcherCompanyAdmin = [config.roles.Admin, config.roles.Dispatcher, config.roles.CompanyAdmin];

const Routes = () => {
  return (
    <Router>
      <Switch>
        <UnauthenticatedRoute
          path={routes.login.path}
          component={routes.login.component}
        />

        <UnauthenticatedRoute
          path={routes.phoneVerification.path}
          component={routes.phoneVerification.component}
        />

        <UnauthenticatedRoute
          path={routes.resetPassword.path}
          component={routes.resetPassword.component}
        />

        <UnauthenticatedRoute
          path={routes.verifyOtp.path}
          component={routes.verifyOtp.component}
        />

        <UnauthenticatedRoute
          path={routes.resetSuccessPage.path}
          component={routes.resetSuccessPage.component}
        />

        <AuthenticatedRoute
          path={routes.verifyPhone.path}
          Component={routes.verifyPhone.component}
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.signUp.path}
          Component={routes.signUp.component}
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.landing.path}
          Component={routes.landing.component}
          exact
          roles={common}
        />
        <AuthenticatedRoute
          path={routes.notification.path}
          Component={routes.notification.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.notificationForm.path}
          Component={routes.notificationForm.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.notificationEdit.path}
          Component={routes.notificationEdit.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.userProfile.path}
          Component={routes.userProfile.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.userProfileEdit.path}
          Component={routes.userProfileEdit.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.serviceForm.path}
          Component={routes.serviceForm.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.serviceEdit.path}
          Component={routes.serviceEdit.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.serviceList.path}
          Component={routes.serviceList.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.serviceDetails.path}
          Component={routes.serviceDetails.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.subServiceDetails.path}
          Component={routes.subServiceDetails.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.subServiceForm.path}
          Component={routes.subServiceForm.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.subServiceEdit.path}
          Component={routes.subServiceEdit.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.questionForm.path}
          Component={routes.questionForm.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.questionEdit.path}
          Component={routes.questionEdit.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.applicationList.path}
          Component={routes.applicationList.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.riderApplicationList.path}
          Component={routes.riderApplicationList.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.driverApplicationList.path}
          Component={routes.driverApplicationList.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.applicationDetails.path}
          Component={routes.applicationDetails.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.riderApplicationDetails.path}
          Component={routes.riderApplicationDetails.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.driverApplicationDetails.path}
          Component={routes.driverApplicationDetails.component}
          exact
          roles={admin}
        />

        <AuthenticatedRoute
          path={routes.bookingList.path}
          Component={routes.bookingList.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.bookingDetail.path}
          Component={routes.bookingDetail.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.taskCreate.path}
          Component={routes.taskCreate.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.taskList.path}
          Component={routes.taskList.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.taskDetail.path}
          Component={routes.taskDetail.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.sahayogiList.path}
          Component={routes.sahayogiList.component}
          exact
          roles={common}
        />
        <AuthenticatedRoute
          path={routes.sahayogiDetail.path}
          Component={routes.sahayogiDetail.component}
          exact
          roles={common}
        />
        <AuthenticatedRoute
          path={routes.sahayogiForm.path}
          Component={routes.sahayogiForm.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.editSahayogi.path}
          Component={routes.editSahayogi.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.rideHistory.path}
          Component={routes.rideHistory.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.riderList.path}
          Component={routes.riderList.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.riderDetail.path}
          Component={routes.riderDetail.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.riderAdd.path}
          Component={routes.riderAdd.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.editRider.path}
          Component={routes.editRider.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.tripHistory.path}
          Component={routes.tripHistory.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.driverList.path}
          Component={routes.driverList.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.driverDetail.path}
          Component={routes.driverDetail.component}
          exact
          roles={common}
        />

        <AuthenticatedRoute
          path={routes.driverAdd.path}
          Component={routes.driverAdd.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.driverEdit.path}
          Component={routes.driverEdit.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.users.path}
          Component={routes.users.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.dispatchers.path}
          Component={routes.dispatchers.component}
          exact
          roles={adminCompanyAdmin}
        />

        <BasicRoute
          path={routes.notFoundPage.path}
          Component={routes.notFoundPage.component}
          exact
        />

        <AuthenticatedRoute
          path={routes.dispatcherForm.path}
          Component={routes.dispatcherForm.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.dispatcherEdit.path}
          Component={routes.dispatcherEdit.component}
          exact
          roles={adminCompanyAdmin}
        />

        <AuthenticatedRoute
          path={routes.ride.path}
          Component={routes.ride.component}
          exact
          roles={dispatcher}
        />

        <AuthenticatedRoute
          path={routes.passenger.path}
          Component={routes.passenger.component}
          exact
          roles={dispatcher}
        />
        <AuthenticatedRoute
          path={routes.passengerHistory.path}
          Component={routes.passengerHistory.component}
          exact
          roles={dispatcher}
        />
        <AuthenticatedRoute
          path={routes.companyList.path}
          Component={routes.companyList.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.companyForm.path}
          Component={routes.companyForm.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.companyEdit.path}
          Component={routes.companyEdit.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.companyAdminList.path}
          Component={routes.companyAdminList.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.companyAdminForm.path}
          Component={routes.companyAdminForm.component}
          exact
          roles={admin}
        />
        <AuthenticatedRoute
          path={routes.companyAdminEdit.path}
          Component={routes.companyAdminEdit.component}
          exact
          roles={admin}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
