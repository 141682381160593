import {
  createContext,
  useState,
  useContext,
  ReactChild,
} from 'react';

const Username = createContext({});

interface IProps {
  children: ReactChild;
}

export const UsernameProvider = ({ children }: IProps) => {
  const [username, setUsername] = useState('');

  const setValues = (value: string) => {
    setUsername(value);
  };

  return (
    <Username.Provider
      value={{
        username,
        setValues,
      }}
    >
      {children}
    </Username.Provider>
  );
};

export const useUsername: any = () => useContext(Username);
