import { useState } from 'react';
import { createBrowserHistory } from 'history';
import {
  ThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import { Toaster } from 'react-hot-toast';

import { IUser } from '../core/interfaces/IUser';
import Routes from './routes';
import { CurrentUserDetails } from '../core/context/user';
import { CssBaseline } from '@material-ui/core';
import { RoleProvider } from '../core/context/role';
import { NavProvider } from '../core/context/navbar';
import { ActiveTabProvider } from '../core/context/activeTab';
import { ServiceAvailabilityProvider } from '../core/context/serviceAvailability';
import { UsernameProvider } from '../core/context/Username';
import { toastStyle } from '../style';
import { LocationProvider } from '../core/context/Location';

const font = '"Poppins", sans-serif';
const theme = createMuiTheme({
  typography: {
    fontFamily: font,
  },
  palette: {
    primary: {
      main: '#F45B23',
      dark: '#D98100',
      light: '#D98100',
    },
    secondary: {
      main: '#D3D3D3',
      light: '#A8A8A8',
      dark: '#808080',
    },
  },
});

export const history = createBrowserHistory();

const App = () => {
  const [currentUser, setCurrentUser] = useState<IUser | {}>({});

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NavProvider>
          <RoleProvider>
            <ActiveTabProvider>
              <ServiceAvailabilityProvider>
                <LocationProvider>
                  <UsernameProvider>
                    <CurrentUserDetails.Provider
                      value={{
                        currentUser,
                        setCurrentUser,
                      }}
                    >
                      <Routes />
                    </CurrentUserDetails.Provider>
                  </UsernameProvider>
                </LocationProvider>
              </ServiceAvailabilityProvider>
            </ActiveTabProvider>
          </RoleProvider>
        </NavProvider>
      </ThemeProvider>
      <Toaster position="bottom-center" toastOptions={toastStyle} />
    </>
  );
};

export default App;
