import {
  createContext,
  useState,
  useContext,
  ReactChild,
  useEffect,
} from 'react';

const Location = createContext({});

interface IProps {
  children: ReactChild;
}

export const LocationProvider = ({ children }: IProps) => {
  const [latitude, setLatitude] = useState<any>('');
  const [longitude, setLongitude] = useState<any>('');

  useEffect(() => {
    if (!latitude || !longitude) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, [latitude, longitude]);

  const setValues = (latitudeData: string, longitudeData: string) => {
    setLatitude(latitudeData);
    setLongitude(longitudeData);
  };

  return (
    <Location.Provider
      value={{
        latitude,
        longitude,
        setValues,
      }}
    >
      {children}
    </Location.Provider>
  );
};

export const useLocationContext: any = () => useContext(Location);
