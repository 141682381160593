const config = {
  apiUrl: process.env.REACT_APP_BASE_URL,
  tokenName: 'token',
  roles: {
    Admin: 'ADMIN',
    Dispatcher: 'DISPATCHER',
    CompanyAdmin: 'COMPANY_ADMIN',
  },
  reactLimit: process.env.REACT_APP_LIMIT || 20,
};

export default config;
