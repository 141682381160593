import {
  createContext,
  useState,
  useContext,
  ReactChild,
} from 'react';

const NavTitle = createContext({});

interface IProps {
  children: ReactChild;
}

export const NavProvider = ({ children }: IProps) => {
  const [title, setTitle] = useState('Dashboard');

  const setValues = (value: string) => {
    setTitle(value);
  };

  return (
    <NavTitle.Provider
      value={{
        title,
        setValues,
      }}
    >
      {children}
    </NavTitle.Provider>
  );
};

export const useTitle: any = () => useContext(NavTitle);
