import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  addBtn: {
    background: (props: any) => props.background,
    width: (props: any) => props.width || '8rem',
    height: (props: any) => props.height || '1.5rem',
    color: (props: any) => props.color,
    '&:hover': {
      background: (props: any) => props.background,
    },
  },
});

export { useStyles };
