import { createContext, useState, useContext } from 'react';
const ActiveTabContext = createContext({});

export const ActiveTabProvider = ({ children }: any) => {
  const [activeTab, setActiveTab] = useState('riders');

  const setValues = (value: string) => {
    setActiveTab(value);
  };

  return (
    <ActiveTabContext.Provider value={{ activeTab, setValues }}>
      {children}
    </ActiveTabContext.Provider>
  );
};

export const useActiveTab: any = () => useContext(ActiveTabContext);
